<template>
  <view-container name="security">
    <view-header>
      <custom-header :router-back="'/settings'">
        <template v-slot:default>
          <svg-icon size="size24x24" :name="'icoArrowLeft'" />Feedback
        </template>
      </custom-header>
    </view-header>
    <view-content>
      <p class='feedback__content'>
        Dear athletes,<br><br>

        Your feedback and suggestions create a giant input into our platform development process. To contribute any feedback, please kindly e-mail to <a href='mailto:contact@competa.co'>contact@competa.co</a>, so our team can review your suggestions and take them into immediate consideration.
      </p>
    </view-content>
  </view-container>
</template>

<script>
export default {
  name: 'Feedback',
}
</script>

<style scoped lang="stylus">
.feedback__content {
  margin-top:30px
  line-height:1.5
  text-align justify
  font-size:15px

}
.security-view {
  .view-content {
    padding 0 16px !important
  }
  .container-fluid {
    padding 0
  }
}

.settings__nav li a {
  display flex
  align-items center
  margin 0 22px 0 18px
  padding 25px 0
  font-size: 16px;
  line-height: 140%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: #D8D8D8;
  text-decoration none

  .size20x20 {
    margin-right 18px
  }


}
.settings__nav li:last-child a {
  border none
}
</style>
